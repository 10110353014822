import styles from "../menu.module.css"
// import Img from "gatsby-image"

import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

export default function Menu({ path }) {
  const [display, setDisplay] = useState(false)
  const [alreadyClicked, setAlreadyClicked] = useState(false)

  function handleClick(e) {
    //al apretar boton de cerrar se tiene que cerrar
    console.log(e)
    setDisplay(e)
    return
  }
  function handleClass() {
    //para manejar animaciones
    if (!alreadyClicked && !display) {
      return [styles.overlay, styles.classStart].join(" ") //pagina acaba de cargar y no se tiene que mostrar nada
    } else if (alreadyClicked && display) {
      return [styles.overlay, styles.classBlock].join(" ") //se tiene que abrir con animacion
    } else if (alreadyClicked && !display) {
      return [styles.overlay, styles.classNone].join(" ") //se tiene que cerrar con animacion
    }
  }

  return (
    <>
      <svg
        width="33"
        height="23"
        viewBox="0 0 33 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.svg}
        onClick={() => {
          if (!alreadyClicked) {
            setDisplay(true)
            setAlreadyClicked(true)
          } else {
            setDisplay(true)
          }
        }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.375 22.25H32.625V18.6667H0.375V22.25ZM0.375 13.2917H32.625V9.70833H0.375V13.2917ZM0.375 0.75V4.33333H32.625V0.75H0.375Z"
          fill="#404040"
        />
      </svg>
      <Overlay //componente de overlay que reribe de props funciones para cambiar estado de clase menu
        path={path}
        overlayState={handleClass}
        changeState={handleClick}
      />
    </>
  )
}

function Overlay({ path, overlayState, changeState }) {
  const activeLink = { color: "#D19B20" }

  return (
    //quitar
    <div className={overlayState()}>
      <div className={styles.closebtn}>
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => {
            changeState(false)
          }}
        >
          <path
            d="M1.5 1L20.5 20M20.5 1L11 10.5L1.5 20"
            stroke="#D6D6D6"
            strokeWidth="2"
          />
        </svg>
      </div>

      <div className={styles.overlayContent}>
        <Link to="/" activeStyle={activeLink}>
          Inicio
        </Link>
        <Link to="/nosotros" activeStyle={activeLink}>
          Quienes Somos
        </Link>
        <Link to="/areas-de-practica" activeStyle={activeLink}>
          Areas de Practica
        </Link>
        <a
          href="#footer"
          onClick={() => {
            changeState(false)
          }}
        >
          Contacto
        </a>
        <span className={styles.cambiarIdioma}>Cambiar idioma</span>
        <div className={styles.overlayLanguages}>
          <Link to={path.replace("/fr", "")} activeStyle={activeLink}>
            ES
          </Link>
          <Link to={path.replace("/fr", "/en")} activeStyle={activeLink}>
            EN
          </Link>
          <Link to={path} activeStyle={activeLink}>
            FR
          </Link>
          <Link to={path.replace("/fr", "/de")} activeStyle={activeLink}>
            DE
          </Link>
        </div>
      </div>
    </div>
  )
}
